import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { convertStringToId, randomKey } from '../../functions/common';

//* Expects Hero images from a field referencing Hero Image media entities,
//* following the structure defined in the ...MediaDataHeroImage fragment

export default function HeroImageSlide({ node, slideCount, heroBlockTitle }) {
  if (!node) {
    return <React.Fragment key={randomKey()}></React.Fragment>;
  }
  const MediaDataHeroImage = node,
    imageData =
      getImage(MediaDataHeroImage.customLocalFieldMediaImage?.childImageSharp?.gatsbyImageData) ||
      MediaDataHeroImage.customLocalFieldMediaImage?.publicURL ||
      null,
    loadingType = slideCount < 2 ? 'eager' : 'lazy',
    sourcePath = MediaDataHeroImage.customLocalFieldMediaImage?.publicURL || null;

  return imageData ? (
    <div
      className="hero-block__slide-wrapper"
      key={`${convertStringToId(sourcePath.split('/')[3])}-${slideCount}`}
    >
      {typeof imageData === 'object' ? (
        <GatsbyImage
          className="hero-block__image"
          image={imageData}
          alt={MediaDataHeroImage.field_media_image?.alt}
          loading={loadingType}
        />
      ) : (
        <img src={imageData} alt={MediaDataHeroImage.field_media_image?.alt} loading="eager" />
      )}
      <div className={MediaDataHeroImage.field_gradient_overlay ? 'hero-block__overlay-container' : ''}>
        {heroBlockTitle && <div className="hero-block__title" dangerouslySetInnerHTML={{ __html: heroBlockTitle }} />}
        <div
          className="hero-block__credit"
          dangerouslySetInnerHTML={{
            __html: MediaDataHeroImage.field_caption?.processed,
          }}
        />
      </div>
    </div>
  ) : (
    <React.Fragment key={node.id}></React.Fragment>
  );
}
